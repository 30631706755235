export interface IDifficulty {
  index: number;
  label: string;
}

export const DIFFICULTIES: Array<IDifficulty> = [
  {
    index: 1,
    label: "leicht",
  },
  {
    index: 2,
    label: "etwas anstrengend",
  },
  {
    index: 3,
    label: "anstrengend",
  },
  {
    index: 4,
    label: "sehr anstrengend",
  },
];
