import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"yc_event_search_results"},[_c(VSpacer,{staticClass:"py-2"}),_c(VList,_vm._l((_vm.eventlist),function(event){return _c(VListItem,{key:event.id,staticClass:"yc_search_result_listitem",on:{"click":function($event){return _vm.showEvent(event.id)}}},[_c(VRow,{staticClass:"yc_textalign_center pr-5",attrs:{"align":"center"}},[_c(VCol,{staticClass:"py-sm-0 py-1 col-6 col-sm-3"},[_vm._v(_vm._s(_vm.getLocalDateString(event.datetime)))]),_c(VCol,{staticClass:"py-sm-0 py-1 col-6 col-sm-3"},[_c('i18n',{attrs:{"path":'activities.' + event.activity.description}},[_vm._v("­")])],1),_c(VCol,{staticClass:"py-sm-0 py-1 col-6 col-sm-3 justify-center"},[_c('div',{staticClass:"iconElement"},[_c(VImg,{staticClass:"yc_difficulty_icon yc_result_icon",attrs:{"max-width":"25%","contain":true,"src":_vm.getDifficultyIcon(event.difficulty)}})],1)]),_c(VCol,{staticClass:"py-sm-0 py-1 col-6 col-sm-3 justify-center"},[_c('div',{staticClass:"iconElement"},[_c(VImg,{staticClass:"yc_mood_icon yc_result_icon",attrs:{"max-width":"25%","contain":true,"src":_vm.getMoodIcon(event.mood)}})],1)])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }