import Repository from "./Repository";
import Activity from '@/models/Activity';

const resource = "/v1/activities/";

class ActivityRepository {
  async get(): Promise<Activity[]> {
    const response = await Repository.get(resource);
    return await response.json()
  }
}

export default new ActivityRepository();