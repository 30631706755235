































































































































import { Vue, Component } from "vue-property-decorator"
import EventSearchResults from "@/components/search/EventSearchResults.vue"
import EventSearchFilters from "@/components/search/EventSearchFilters.vue"
import ShowEvent from "@/views/ShowEvent.vue"
import Activity from "@/models/Activity"
import EventFilter from "@/models/EventFilter"
import Event from "@/models/Event"
import ActivityRepository from "@/api/ActivityRepository"
import EventRepository, { EventSearchParams } from "@/api/EventRepository"
import TrackingRepository from "@/api/TrackingRepository"

@Component({
  components: {
    ShowEvent,
    EventSearchResults,
    EventSearchFilters
  }
})
export default class SearchView extends Vue {
  currEventId: number = -1
  scrollPosition: number = 0
  showEvent: boolean = false
  activities: Activity[] = []
  eventSearchResults: Event[] = []
  filter_options: { filter_nr: number; filter_name: string }[] = [
    { filter_nr: 0, filter_name: "date" },
    { filter_nr: 1, filter_name: "loc" },
    { filter_nr: 2, filter_name: "difficulty" },
    { filter_nr: 3, filter_name: "mood" },
    { filter_nr: 4, filter_name: "activity" }
  ]
  order_options: { order_name: string; id: number }[] = [
    { order_name: "date_desc", id: 0 },
    { order_name: "date_asc", id: 1 }
  ]
  sortBy: number = 0
  chosen_filters: EventFilter = {
    search_date_min: "",
    search_date_max: "",
    search_location: "",
    search_difficulty: "",
    search_mood: "",
    search_activity: ""
  }
  search_string: string = ""
  filtermode: boolean = false
  newSearch: boolean = true
  moreResultsExpected: boolean = true
  searchOffset: number = 0
  standardOffset: number = 5
  searchLimit: number = 5
  noSearchResults: boolean = false
  ordering: number = 0
  filter_search_string: string = ""
  timestamp: number = Date.now()

  get language() {
    return navigator.language
  }
  get today() {
    const now = new Date(Date.now())
    return now.toISOString().substring(0, 10)
  }

  created(): void {
    this.fetchActivities()
    this.searchEvent(true)
  }
  deleteEvent(eventId: number): void {
    this.fetchActivities()
    //delete eventId from searchResult array
    this.eventSearchResults = this.eventSearchResults.filter((e: Event) => e.id !== eventId)
  }
  openShowEvent(event: number): void {
    this.currEventId = event
    this.scrollPosition = window.pageYOffset
    this.showEvent = true
    document.body.style.position = "fixed"
    document.body.style.width = "100%"
  }
  closeShowEvent(): void {
    this.currEventId = -1
    document.body.style.position = ""
    window.scrollTo(0, this.scrollPosition)
    this.showEvent = false
  }
  buildSearchParams(): EventSearchParams {
    const searchParams: EventSearchParams = {
      search: this.search_string,
      date_min: this.chosen_filters.search_date_min,
      date_max: this.chosen_filters.search_date_max,
      location: this.chosen_filters.search_location,
      difficulty: this.chosen_filters.search_difficulty,
      mood: this.chosen_filters.search_mood,
      activity: this.chosen_filters.search_activity,
      offset: this.searchOffset,
      limit: this.searchLimit
    }

    if (this.sortBy === 0) {
      searchParams.ordering = "-datetime"
    } else searchParams.ordering = "datetime"
    return searchParams
  }
  async logSearch(searchParams: EventSearchParams, numResults: number): Promise<void> {
    const minDate =
      searchParams.date_min != "" ? ", Startdatum '" + searchParams.date_min + "'" : ""
    const maxDate = searchParams.date_max != "" ? ", Enddatum '" + searchParams.date_max + "'" : ""
    const searchTerm =
      searchParams.search != "" ? ", Suchbegriff '" + searchParams.search + "'" : ""
    const difficulty =
      searchParams.difficulty != ""
        ? ", Schwierigkeit '" +
          this.$t("difficultyLevel.difficulty_" + searchParams.difficulty) +
          "'"
        : ""
    const mood =
      searchParams.mood != ""
        ? ", Stimmung '" + this.$t("moods.mood_" + searchParams.mood) + "'"
        : ""
    const location = searchParams.location != "" ? ", Ort '" + searchParams.location + "'" : ""
    const activity =
      searchParams.activity != ""
        ? ", Event '" + this.$t("activities." + searchParams.activity) + "'"
        : ""
    const sorting =
      searchParams.ordering == "-datetime"
        ? " Sortierung 'absteigend'"
        : " Sortierung 'aufsteigend'"
    let groupID = "HOME:"

    await TrackingRepository.createLog(
      groupID +
        "Suche Event mit" +
        sorting +
        minDate +
        maxDate +
        difficulty +
        mood +
        location +
        activity +
        searchTerm +
        " - " +
        numResults +
        " Ergebnis(se)"
    )
  }
  async searchEvent(newSearch: boolean): Promise<void> {
    if (newSearch) {
      this.moreResultsExpected = true
      this.eventSearchResults = []
    }
    this.changeOffsetAndLimit(newSearch)

    const searchParams = this.buildSearchParams()

    try {
      const filtered_events = await EventRepository.searchEvents(searchParams)

      this.logSearch(searchParams, filtered_events.count)

      if (filtered_events.count == 0 && this.eventSearchResults.length == 0) {
        this.noSearchResults = true
        this.moreResultsExpected = false
        return
      }
      this.noSearchResults = false
      if (filtered_events.count < this.standardOffset) this.moreResultsExpected = false

      filtered_events.results.forEach((e) => this.eventSearchResults.push(e))
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.events"))
      /* eslint-disable no-console */
      console.error("Error fetching events: " + error)
      this.noSearchResults = true
    }
  }
  changeOffsetAndLimit(newSearch: boolean): void {
    if (newSearch) {
      this.searchOffset = 0
      this.searchLimit = this.standardOffset
    } else {
      this.searchOffset += this.standardOffset
    }
  }
  applyFilters(filters: EventFilter): void {
    this.filtermode = false

    if (filters.search_date_min)
      this.chosen_filters.search_date_min = new Date(filters.search_date_min).toISOString()
    else this.chosen_filters.search_date_min = ""

    if (filters.search_date_min && !filters.search_date_max) {
      this.chosen_filters.search_date_max = this.getNextDay(filters.search_date_min).toISOString()
    } else if (filters.search_date_min && filters.search_date_max) {
      this.chosen_filters.search_date_max = this.getNextDay(filters.search_date_max).toISOString()
    } else this.chosen_filters.search_date_max = ""
    this.chosen_filters.search_mood = filters.search_mood
    this.chosen_filters.search_difficulty = filters.search_difficulty
    this.chosen_filters.search_location = filters.search_location
    this.chosen_filters.search_activity = filters.search_activity
  }
  getNextDay(day: string): Date {
    var day_curr = new Date(day)
    var next_day = new Date(day_curr)
    next_day.setDate(day_curr.getDate() + 1)
    return next_day
  }
  getPrevDay(day: string): Date {
    var day_curr = new Date(day)
    var prev_day = new Date(day_curr)
    prev_day.setDate(day_curr.getDate() - 1)
    return prev_day
  }
  getChosenFilterNr(): number {
    var chosen_filter_values = Object.values(this.chosen_filters)
    return chosen_filter_values.filter((val) => val).length
  }
  getLocalFilterDateString(): string {
    var min_date = ""
    var max_date = ""
    if (this.chosen_filters.search_date_min)
      min_date = new Date(this.chosen_filters.search_date_min).toLocaleDateString()
    if (this.chosen_filters.search_date_max)
      max_date = this.getPrevDay(this.chosen_filters.search_date_max).toLocaleDateString()

    var datestring = ""
    if (min_date) datestring += min_date
    if (max_date) datestring += " - " + max_date

    return datestring
  }
  removeSingleFilter(filter: String): void {
    this.timestamp = Date.now()
    switch (filter) {
      case "mood":
        this.chosen_filters.search_mood = ""
        break
      case "difficulty":
        this.chosen_filters.search_difficulty = ""
        break
      case "location":
        this.chosen_filters.search_location = ""
        break
      case "date":
        this.chosen_filters.search_date_min = ""
        this.chosen_filters.search_date_max = ""
        break
      case "activity":
        this.chosen_filters.search_activity = ""
        break
    }
    this.searchEvent(true)
  }
  async fetchActivities(): Promise<void> {
    try {
      this.activities = await ActivityRepository.get()
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.activities"))
      /*eslint-disable no-console */
      console.error("Error fetching activities: " + error)
    }
  }
}
