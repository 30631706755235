




























































































































































import { Vue, Prop, Component } from "vue-property-decorator"
import EventFilter from "@/models/EventFilter"
import { MOODS, IMood } from "@/models/Mood"
import { DIFFICULTIES, IDifficulty } from "@/models/Difficulty"
import { AssetHelper } from "@/utils/AssetHelper"

@Component({})
export default class EventSearchFilters extends Vue {
  @Prop({ type: Array, default: () => [] }) filter_options!: EventFilter[]
  @Prop({ type: String }) s_mood: string | undefined
  @Prop({ type: String }) s_difficulty: string | undefined
  @Prop({ type: String }) s_location: string | undefined
  @Prop({ type: String }) s_date_min: string | undefined
  @Prop({ type: String }) s_date_max: string | undefined
  @Prop({ type: String }) s_activity: string | undefined
  @Prop({ type: Array }) activities: any[] | undefined

  locations: string[] = ["clinic", "home"]
  difficulties: IDifficulty[] = DIFFICULTIES
  moods: IMood[] = MOODS.reverse()
  search_date: string[] = []
  display_date: string = ""
  search_location: string = ""
  search_difficulty: string = ""
  search_mood: string = ""
  search_activity: string = ""
  picker_date: string = ""

  get today() {
    const now = new Date(Date.now())
    return now.toISOString().substring(0, 10)
  }
  get language() {
    return navigator.language
  }

  created() {
    this.applyChosenFilters()
  }
  getMoodIcon(index: number): NodeRequire {
    return AssetHelper.getMoodIcon(index, null, false)
  }
  getDifficultyIcon(index: number): NodeRequire {
    return AssetHelper.getDifficultyIcon(index, null, false)
  }
  getMoodLabel(index: number): string | undefined {
    return this.$t("moods.mood_" + index) as string
  }
  getDifficultyLabel(index: number): string | undefined {
    return DIFFICULTIES.find((difficulty) => difficulty.index == index)?.label
  }
  getFiltersObject(): EventFilter {
    let date_min = ""
    let date_max = ""

    if (this.search_date.length == 2)
      if (this.search_date[0] > this.search_date[1]) {
        date_min = this.search_date[1]
        date_max = this.search_date[0]
      } else {
        date_min = this.search_date[0]
        date_max = this.search_date[1]
      }
    else if (this.search_date.length == 1) {
      date_min = this.search_date[0]
    }

    let chosenFilters = {
      search_mood: this.search_mood,
      search_difficulty: this.search_difficulty,
      search_location: this.search_location,
      search_date_min: date_min,
      search_date_max: date_max,
      search_activity: this.search_activity
    }
    return chosenFilters as EventFilter
  }
  closeFilters(): void {
    let chosenFilters = this.getFiltersObject()
    this.$emit("filtersChosen", chosenFilters)
  }
  startSearch(): void {
    let chosenFilters = this.getFiltersObject()
    this.$emit("startFilterSearch", chosenFilters)
  }
  resetFilters(): void {
    this.search_date = []
    this.search_location = ""
    this.search_difficulty = ""
    this.search_mood = ""
    this.display_date = ""
    this.search_activity = ""
    let chosenFilters = this.getFiltersObject()
    this.$emit("filtersChosen", chosenFilters)
  }
  getPrevDay(day: string): Date {
    let day_curr = new Date(day)
    let prev_day = new Date(day_curr)
    prev_day.setDate(day_curr.getDate() - 1)
    return prev_day
  }
  setDate(): void {
    if (
      (this.search_date.length == 2 && this.search_date[0] == this.search_date[1]) ||
      this.search_date.length == 1
    )
      this.display_date = new Date(this.search_date[0]).toLocaleDateString()
    else if (this.search_date.length == 2)
      if (this.search_date[0] > this.search_date[1])
        this.display_date =
          new Date(this.search_date[1]).toLocaleDateString() +
          " - " +
          new Date(this.search_date[0]).toLocaleDateString()
      else
        this.display_date =
          new Date(this.search_date[0]).toLocaleDateString() +
          " - " +
          new Date(this.search_date[1]).toLocaleDateString()
    // this.search_date = date;
  }
  applyChosenFilters(): void {
    this.search_mood = this.s_mood as string
    this.search_location = this.s_location as string
    this.search_difficulty = this.s_difficulty as string
    if (this.s_date_min) this.search_date.push(this.s_date_min)
    if (this.s_date_max) this.search_date.push(this.getPrevDay(this.s_date_max).toISOString())
    if (this.search_date.length > 0) this.setDate()
    this.search_activity = this.s_activity as any
  }
  resetSingleFilter(filter: string, event: Event): void {
    switch (filter) {
      case "location":
        this.search_location = ""
        break
      case "mood":
        this.search_mood = ""
        break
      case "difficulty":
        this.search_difficulty = ""
        break
      case "date":
        this.search_date = []
        this.display_date = ""
        break
      case "activity":
        this.search_activity = ""
        break
    }
    event.stopPropagation()
  }
}
