















































import { AssetHelper } from "@/utils/AssetHelper";
import Event from '@/models/Event'
import {Component, Vue, Prop} from 'vue-property-decorator'

@Component({})
export default class EventSearchResults extends Vue {
  @Prop({type: Array, required: true}) eventlist!: Event[]
  getDifficultyIcon(difficulty: number): NodeRequire {
    return AssetHelper.getDifficultyIcon(difficulty, null, false);
  }
  getMoodIcon(mood: number): NodeRequire {
    return AssetHelper.getMoodIcon(mood, null, false);
  }
  getLocalDateString(date: string): string {
    return new Date(date).toLocaleDateString();
  }
  showEvent(id: number): void {
    this.$emit("showEvent", id);
  }
}
